<template>
  <div>
    <section id="login" class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <div class="brand mb-3">
          <h3>WATCH MODEL ASSETS</h3>
          <p class="text-center">
            <small>
              <b>{{ model.name }}</b>
              <br />Upload the needed assets fot this watch model.</small
            >
          </p>
        </div>

        <!-- OVERVIEW -->
        <div class="card-title mt-3">
          <h3>
            <small><i class="fal fa-images"></i></small> General
          </h3>
        </div>
        <div class="jblock jblock-grid">
          <div class="jcard-mini">
            <label for="title"><i class="fad fa-image"></i> Logo</label>
            <div class="d-flex justify-items-center" v-if="model.assets.logo">
              <img class="preview mx-auto" :src="model.assets.logo" alt="" />
            </div>
            <div v-else class="default-upload-image"></div>
            <div class="btn-container-center mb-0 mt-3">
              <button @click="openUploadDialog('logo')" class="jbtn jbtn-sm">
                <i class="fa fa-upload"></i>
              </button>
            </div>
          </div>
          <div class="jcard-mini">
            <label for="title"><i class="fad fa-image"></i> Front</label>
            <div class="d-flex justify-items-center" v-if="model.assets.front">
              <img class="preview mx-auto" :src="model.assets.front" alt="" />
            </div>
            <div v-else class="default-upload-image"></div>
            <div class="btn-container-center mb-0 mt-3">
              <button @click="openUploadDialog('front')" class="jbtn jbtn-sm">
                <i class="fa fa-upload"></i>
              </button>
            </div>
          </div>

          <div class="jcard-mini">
            <label for="title"><i class="fad fa-image"></i> Side</label>
            <div class="d-flex justify-items-center" v-if="model.assets.side">
              <img class="preview mx-auto" :src="model.assets.side" alt="" />
            </div>
            <div v-else class="default-upload-image"></div>
            <div class="btn-container-center mb-0 mt-3">
              <button @click="openUploadDialog('side')" class="jbtn jbtn-sm">
                <i class="fa fa-upload"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="card-title mt-5">
          <h3>
            <small><i class="fal fa-images"></i></small> Details
          </h3>
        </div>
        <div class="jblock jblock-grid">
          <div class="jcard-mini">
            <label for="title"><i class="fad fa-image"></i> Movement</label>
            <div class="d-flex justify-items-center" v-if="model.assets.movement">
              <img class="preview mx-auto" :src="model.assets.movement" alt="" />
            </div>
            <div v-else class="default-upload-image"></div>
            <div class="btn-container-center mb-0 mt-3">
              <button @click="openUploadDialog('movement')" class="jbtn jbtn-sm">
                <i class="fa fa-upload"></i>
              </button>
            </div>
          </div>

          <div class="jcard-mini">
            <label for="title"><i class="fad fa-image"></i> Dial</label>
            <div class="d-flex justify-items-center" v-if="model.assets.dial">
              <img class="preview mx-auto" :src="model.assets.dial" alt="" />
            </div>
            <div v-else class="default-upload-image"></div>
            <div class="btn-container-center mb-0 mt-3">
              <button @click="openUploadDialog('dial')" class="jbtn jbtn-sm">
                <i class="fa fa-upload"></i>
              </button>
            </div>
          </div>

          <div class="jcard-mini">
            <label for="title"><i class="fad fa-image"></i> Case</label>
            <div class="d-flex justify-items-center" v-if="model.assets.case">
              <img class="preview mx-auto" :src="model.assets.case" alt="" />
            </div>
            <div v-else class="default-upload-image"></div>
            <div class="btn-container-center mb-0 mt-3">
              <button @click="openUploadDialog('case')" class="jbtn jbtn-sm">
                <i class="fa fa-upload"></i>
              </button>
            </div>
          </div>

          <div class="jcard-mini">
            <label for="title"><i class="fad fa-image"></i> 3d Model</label>
            <div class="default-upload-image"></div>
            <div class="btn-container-center mb-0 mt-3">
              <button @click="openUploadDialog('3d')" class="jbtn jbtn-sm">
                <i class="fa fa-upload"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ----- upload modal --------------------------------- -->
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      id="upload-modal"
      ref="upload-modal"
      size="lg"
      centered
      title="Upload"
    >
      <div class="jdialog-top">
        <div class="close-container">
          <h3 class="text-capitalize">
            <strong>{{ uploadDialogTitle }}</strong>
          </h3>
          <span @click="$bvModal.hide('upload-modal')" class="ml-auto jclose"
            ><i class="fa fa-times "></i
          ></span>
        </div>
        <p><small>Upload an asset file.</small></p>
      </div>
      <div class="jdialog-main">
        <!-- picture input - upload new cover -->
        <picture-input
          v-if="!isUploadingImg"
          key="picture-input"
          ref="pictureInput"
          @change="onImageChange"
          @remove="onImageRemove"
          :prefill="imageFile"
          :width="400"
          :height="400"
          :removable="true"
          removeButtonClass="jbtn jbtn-sm jbtn-red"
          accept="image/jpeg, image/png, image/gif"
          buttonClass="jbtn jbtn-sm"
          :customStrings="{
            upload: '<h1>Upload it!</h1>',
            drag: '<small>Drag and drop cover image here</small>'
          }"
        >
        </picture-input>
        <div v-if="isUploadingImg" key="uploading-img" class="text-center">
          <p class="mb-3">
            <i class="fal fa-cloud-upload"></i> Please wait while the asset is being uploaded:
          </p>
          <p>
            Progress: {{ uploadValue.toFixed() + "%" }}
            <progress id="progress" :value="uploadValue" max="100"></progress>
          </p>
        </div>
      </div>

      <!-- dialog bottom -->
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('upload-modal')" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button v-if="imageFile != null && !isUploadingImg" @click="uploadImage" class="jbtn jbtn-sm">
          <i class="fa fa-upload"></i> Upload
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import C from "@/const";
import toast from "@/assets/js/toast";
import PictureInput from "vue-picture-input";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const storage = firebase.storage;

export default {
  name: "CreateNews",
  components: {
    PictureInput
  },
  data() {
    return {
      //upload
      showPictureInput: false,
      imageFile: null,

      pdfFile: null,
      uploadValue: 0,

      //page status
      isUploadingPdf: false,
      isUploadingImg: false,

      //form error alert
      showInputError: false,
      validationMsg: "",

      //
      uploadItem: "",
      uploadDialogTitle: "Upload",

      //edit form
      editMode: false,
      valid: false,

      //model article
      modelId: "",
      model: {
        assets: {
          front: "",
          side: "",
          case: "",
          movement: "",
          dial: "",
          render: "",
          logo: "",
          warranty_en: "",
          warranty_fr: ""
        }
      },
      editData: null,
      displayData: {
        assets: {
          front: "",
          side: "",
          case: "",
          movement: "",
          dial: "",
          render: "",
          logo: "",
          warranty_en: "",
          warranty_fr: ""
        }
      }
    };
  },
  methods: {
    // SHOW upload image dialog
    openUploadDialog(item) {
      this.uploadItem = item;
      this.uploadDialogTitle = item;
      this.showPictureInput = false;
      this.imageFile = null;
      this.$refs["upload-modal"].show();
    },

    //open pdf file in new tab
    openPdf() {
      console.log("Opening PDF...");
      window.open(this.editData.pdfUrl, "_blank"); //to open in new tab
    },

    uploadPdf() {
      console.log(this.pdfFile.type);
      //make sure only PDFs are uploaded, check for blob type, TODO: check for true MIME type
      if (this.pdfFile.type != "application/pdf") {
        toast.error("The selected file is not a PDF. Plese choose a different file.");
        return;
      }
      this.uploadValue = 0;
      //this.picture = null;
      this.isUploadingPdf = true;
      const storageRef = storage
        .ref("news/" + this.modelId + ".pdf")
        .put(this.pdfFile);
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
          toast.error("Couldn't upload PDF. " + error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            //update news article document and reset file input
            this.editData.hasPdf = true;
            this.editData.pdfUrl = url;
            this.pdfFile = null;
            this.updateNewsAfterPdfUpload();
            toast.success("PDF uploaded.");
          });
        }
      );
    },
    // save news document after a pdf was uploaded or deleted
    updateNewsAfterPdfUpload() {
      var docRef;
      if (this.editMode) docRef = db.collection(C.COLLECTION.NEWS).doc(this.modelId);
      else return;
      docRef
        .set(
          {
            hasPdf: this.editData.hasPdf,
            pdfUrl: this.editData.pdfUrl
          },
          { merge: true }
        )
        .then(() => {
          this.isUploadingPdf = false;
        })
        .catch(error => {
          toast.error("Couldn't update news article after PDF upload/delete. " + error.message);
          this.isUploadingPdf = false;
        });
    },
    deletePdf() {
      if (confirm("Are you sure you want to delete this asset?")) {
        // reference to the file to delete
        var storageRef = storage.ref();
        var pdfRef = storageRef.child("news/" + this.modelId + ".pdf");

        // delete the file
        pdfRef
          .delete()
          .then(() => {
            // update news doc
            this.editData.hasPdf = false;
            this.editData.pdfUrl = "";
            this.updateNewsAfterPdfUpload();
            toast.success("PDF deleted successfully!");
          })
          .catch(error => {
            toast.error("Couldn't delete PDF. " + error.message);
          });
      }
    },
    //upload image
    onImageChange() {
      console.log("New picture selected!");
      if (this.$refs.pictureInput.file) {
        this.imageFile = this.$refs.pictureInput.file;
        console.log(this.imageFile);
      } else {
        console.log("Old browser. No support for Filereader API");
      }
    },
    onImageRemove() {
      console.log("Picture removed!");
      this.imageFile = null;
    },
    uploadImage() {
      this.uploadValue = 0;
      this.isUploadingImg = true;
      const storageRef = storage.ref("models/" + this.modelId + "/" + this.uploadItem)
        .put(this.imageFile);
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
          toast.error("Couldn't upload Image. " + error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            //update document and reset file input

            this.imageFile = null;
            this.showPictureInput = false;
            this.updateModelAfterImgUpload(url);
            toast.success("Asset uploaded.");
          });
        }
      );
    },
    // save news document after an image was uploaded or deleted
    updateModelAfterImgUpload(url) {
      if (this.uploadItem === "front") {
        this.editData = { assets: { front: url } };
      } else if (this.uploadItem === "side") {
        this.editData = { assets: { side: url } };
      } else if (this.uploadItem === "movement") {
        this.editData = { assets: { movement: url } };
      } else if (this.uploadItem === "case") {
        this.editData = { assets: { case: url } };
      } else if (this.uploadItem === "dial") {
        this.editData = { assets: { dial: url } };
      } else {
        return;
      }

      var docRef = db.collection(C.COLLECTION.WATCH_CATALOG).doc(this.modelId);

      docRef
        .set(this.editData, { merge: true })
        .then(() => {
          this.isUploadingImg = false;
          this.uploadItem = "";
          (this.editData = null), this.$refs["upload-modal"].hide();
        })
        .catch(error => {
          toast.error("Couldn't update watch model after IMG upload/delete. " + error.message);
          this.isUploadingImg = false;
        });
    },
    //delete uploaded cover image
    deleteImage() {
      if (confirm("Are you sure you want to delete this asset?")) {
        // reference to the file to delete
        //var storageRef = firebase.storage().ref();
        //var imgRef = storageRef.child("news/" + this.modelId + ".pdf");
        var httpsImgRef = storage.refFromURL(this.editData.coverUrl);

        // delete the file
        httpsImgRef
          .delete()
          .then(() => {
            // update news doc
            this.editData.hasCoverImage = false;
            this.editData.coverUrl = "";
            this.updateNewsAfterImgUpload();
            toast.success("Cover image deleted successfully!");
          })
          .catch(error => {
            toast.error("Couldn't delete IMG. " + error.message);
          });
      }
    }
  },

  created() {
    //init

    //create new article: if no id is passed. edit article: get id and load existing article
    //get news id from params
    if (this.$route.params.id != null && this.$route.params.id != "") {
      this.modelId = this.$route.params.id;
      this.editMode = true;
    }

    //load watch model
    var docRef = db.collection(C.COLLECTION.WATCH_CATALOG).doc(this.modelId);
    docRef
      .onSnapshot(doc => {
        this.model = doc.data();
      })
      .catch(function(error) {
        console.log("Error getting WATCH MODEL document:", error);
      });
  }
};
</script>

<style lang="scss" scoped>
img.preview {
  width: auto;
  height: 220px;
}
.default-upload-image {
  background-image: url("~@/assets/covers/duke-cover-4k.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: auto;
  height: 220px;
}

.btn-container-center {
  margin-bottom: 20px;
}
.picture-input {
  margin-bottom: 30px;
}
</style>
